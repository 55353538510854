<template>
  <!-- 下单管理 -客户订气 -->
  <XkContainer id="customerSubscription">
    <ProductAddition @childrenData="childrenData" :formData="upData" :cusId="postCusId"> </ProductAddition>
    <!-- 下单填写表 -->
    <el-form :inline="true" class="btm-form add-form-box add-form-style " :model="upData" :rules="ordForm" ref="ordForm">
      <el-col :span="upData.CylinderPledge ? 24 : 8">
        <el-form-item label="是否押瓶">
          <el-radio-group v-model="upData.CylinderPledge" @change="changePledge">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
          <template v-if="upData.CylinderPledge">
            <el-input class="moiney-yp" maxLength="50" readonly v-model="upData.AmountReceivable" placeholder="押瓶金额"></el-input>
            <el-button size="mini" type="primary" @click="showPledgeBottle">押瓶信息</el-button>
          </template>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="金额">
          <el-input v-if="$route.params.type == 'wxsl'" maxLength="100" v-model="upData.PayMoney" placeholder="金额" style="width:80px" readonly></el-input>
          <el-input v-else maxLength="100" v-model="upData.Money" placeholder="金额" style="width:80px" readonly></el-input>
          <span style="color:#999"> 优惠券金额：{{ upData.CouponMoney || 0 }}</span>
          <span style="color:#999"> 积分抵扣金额：{{ upData.PointMoney || 0 }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="收货人" prop="Receptor">
          <el-input maxLength="100" v-model="upData.Receptor" placeholder="收货人" clearable></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="收货地址" prop="ReceptAddress">
          <el-input maxLength="100" v-model="upData.ReceptAddress" placeholder="请输入收货地址" clearable></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8" v-if="FloorIsEnable">
        <el-form-item label="楼层费用">
          <el-input v-model="upData.FloorsMoney" :disabled="true" clearable></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="收货电话" prop="ReceptPhone">
          <el-input maxLength="100" v-model="upData.ReceptPhone" placeholder="收货电话" clearable></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="送气工" :prop="gasWork == 1 && upData.OrderType == 4 ? 'OperatorId' : 'noOperatorId'">
          <DeliveryWorker
            :urlParams="operUrlParams"
            @handleChange="getRolePs"
            :url="'getStationOperList'"
            :val.sync="upData.OperatorId"
            placeholder="请选择送气工"
            lable="Remark"
            value="Id"
          ></DeliveryWorker>
          <!-- <XkDropDown :url="'getStationOperList'" @handleChange="getRolePs" :urlParams="operUrlParams" :val.sync="upData.OperatorId" placeholder="请选择送气工" lable="Remark" value="Id"></XkDropDown> -->
        </el-form-item>
      </el-col>
      <el-col :span="3">
        <el-form-item>
          <el-button size="mini" type="primary" @click="showMapPosition">查看位置</el-button>
        </el-form-item>
      </el-col>

      <el-col :span="24">
        <el-form-item label="预约送气时间">
          <el-date-picker
            v-model="setTimeValue"
            type="datetimerange"
            align="right"
            :picker-options="expireTimeOPtion"
            :value-format="'yyyy-MM-dd HH:mm:ss'"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="setChangeTime"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      
      <el-col :span="24" v-if="ordType !== 'wxsl' && orderTimeCanEdit=='1'">
        <el-form-item label="下单时间" prop="OrderTime" :rules="[
            { required: orderTimeCanEdit=='1', message: '请选择下单时间', trigger: 'change' },
          ]">
          <el-date-picker
            v-model="upData.OrderTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="下单时间"
            :clearable="false"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>

      <el-col :span="24">
        <el-form-item label="订单类型">
          <el-radio-group v-model="upData.OrderType" v-if="$route.params.type == 'wxsl'">
            <el-radio :disabled="upData.OrderType !== 1" :label="1">货到付款</el-radio>
            <el-radio :disabled="upData.OrderType !== 4" :label="4">自提</el-radio>
            <el-radio :disabled="upData.OrderType !== 6" :label="6">余额支付</el-radio>
            <el-radio :disabled="upData.OrderType !== 3" :label="3">月结支付</el-radio>
            <el-radio :disabled="upData.OrderType !== 2" :label="2">微信支付</el-radio>
            <el-radio :disabled="upData.OrderType !== 5" :label="5">积分支付</el-radio>
          </el-radio-group>
          <el-radio-group v-model="upData.OrderType" v-else>
            <el-radio :label="1">货到付款</el-radio>
            <el-radio :label="4">自提</el-radio>
            <el-radio :label="6">余额支付</el-radio>
            <el-radio :disabled="gettOrderInfoData.IsMonthLimit === true ? false : true" :label="3">月结支付</el-radio>
            <el-radio disabled :label="2">微信支付</el-radio>
            <el-radio disabled :label="5">积分支付</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="备注">
          <el-input maxLength="100" type="textarea" :rows="1" placeholder="请输入内容" v-model="upData.Remark"> </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item align="right">
          <!-- :disabled="gettOrderInfoData.IsOrder === true ? false : true" -->
          <el-button type="primary" size="medium" round @click="getOrder">订购下单</el-button>
        </el-form-item>
      </el-col>
    </el-form>
    <!-- 地图展示 -->
    <XkMaxDialog :dialogVisible.sync="showMap" :title="`送气工位置`" :getIsfull="false" :titleVisible.sync="showMap" width="1200px">
      <template #content>
        <XkMapDistance @getMapInfo="getMapInfo" :gasAddressPoint="gasAddressPoint" :mapPointData="mapPointData" :address="upData.ReceptAddress"></XkMapDistance>
      </template>
    </XkMaxDialog>
    <!-- 新增压瓶 -->
    <XkMaxDialog :dialogVisible="showMortgage" @closeFun="closeFun" :title="`添加押瓶信息`" :getIsfull="false" :titleVisible.sync="showMortgage" width="1200px">
      <template #content>
        <div style="overflow-y: scroll;padding: 20px;">
          <!-- goinType : 1 是压瓶页面做数据  2是下单页面做押瓶数据 -->
          <UserManageAdd getCustomerType="ordAdd" goinType="2" :propsData="mortgageFrom" @getMortgageInfo="getMortgageInfoFun"></UserManageAdd>
        </div>
      </template>
    </XkMaxDialog>
  </XkContainer>
</template>
<script>
import XkMapDistance from '@/compPages/XkMapDistance';
import DeliveryWorker from '@/components/DropDown/DeliveryWorker';
import { postOrderAdd, getOrderList, postOrderAddWx, getLastOrder } from '@/api/order';
import { getCustomerPointByMoney } from '@/api/customer';
import XkDateRangePick from '@/components/XkDateRangePick';
import { getGetPointsRedemption } from '@/api/sysConfig.js';
import { GetNearbyOperator } from '@/api/deliverOperator.js';
import { mapGetters } from 'vuex';
import ProductAddition from '@/compPages/ProductAddition';
import { getConfigList, getConfigAllList } from '@/api/sysConfig';
import UserManageAdd from '@/views/retreatPressureGasCylinder/components/userManageAdd'; //压瓶
import { postCylinderPledgeAdd } from '@/api/cylinderPledge';
import { getFeesConfigEditFloor } from '@/api/floorDeliveryFee';
import _ from 'lodash';
import { formatDate } from '@/utils/global';
export default {
  name: 'customerSubscription',
  data() {
    return {
      FloorIsEnable: false,
      showMortgage: false,
      mortgageFrom: {},
      postCusId: '',
      expireTimeOPtion: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      gasWork: '',
      setTimeValue: [],
      btnLogin: false, //加载下单按钮
      setStationId: '', //气站id

      tableLoading: false,
      alertAdd: false, //商品新增
      upData: {
        CusId: '', //燃气用户id
        CusName: '', //燃气用户
        CusNumber: '', //客户证号
        OrderType: 1, //默认货到付款
        OrderPhone: '', //下单电话
        CylinderPledge: false,
        CusStationId: '', //燃气用户所属站点Id
        CusStation: '', //燃气用户所属站点
        FloorsMoney: null,
        Receptor: '', //收货人
        ReceptPhone: '', //收货电话
        ReceptAddress: '', //收货地址

        Money: '', //金额
        Remark: '',
        OperatorId: '', //配送员Id
        Operator: '', //:配送员
        Detail: [], //订单详情商品
        Receptor: '',
        ReceptAddress: '',
        ReserveBeginTime: '',
        ReserveEndTime: '',
      },
      ordForm: {
        OperatorId: [{ required: true, message: '请选择送气工', trigger: 'change' }],
        Receptor: [{ required: true, message: '请输入收货人', trigger: 'blur' }],
        ReceptPhone: [{ required: true, message: '请输入收货电话', trigger: 'blur' }],
        ReceptAddress: [{ required: true, message: '请输入收货地址', trigger: 'blur' }],
      },
      jumpOrdOperatorId: false,
      allMoney: 0,
      checked: true,
      tableData: [],
      lastPhone: '', //上一次的联系电话
      pointMoney: 0, //1积分= ？元
      softwareName: {
        lastGasSupplier: false,
      }, // 本地缓存域名等配置信息
      showMap: false,
      mapPointData: [],
      gasAddressPoint: [],
      PcLastDevlieryOperator: 0,
      priceMoney: 0, //单独缓存商品金额用于计算
      IsMonthLimit: false, //月结判断
      // 类型
      ordType: '',
      operUrlParamsStationId: 'AAAAAAAAAAAAAAAAAAAAAA==',
      orderTimeCanEdit: '0',
    };
  },
  components: {
    UserManageAdd,
    DeliveryWorker,
    XkDateRangePick,
    ProductAddition,
    XkMapDistance,
  },
  computed: {
    operUrlParams: function() {
      if (!this.operUrlParamsStationId) {
        return { stationId: 'AAAAAAAAAAAAAAAAAAAAAA==' };
      } else {
        return { stationId: this.operUrlParamsStationId };
      }
    },
    ...mapGetters({
      gettOrderInfoData: 'gettOrderInfoData',
    }),
  },
  watch: {
    gettOrderInfoData: {
      handler(n, o) {
        this.postCusId = this.gettOrderInfoData.Id;
        this.setStationId = this.gettOrderInfoData.StationId; //配送人员入参，气站的id
        this.operUrlParamsStationId = this.gettOrderInfoData.StationId;
        this.upData.Receptor = this.gettOrderInfoData.Name; //收货人
        this.upData.FloorsMoney = this.gettOrderInfoData.FloorsMoney; //楼层费
        let phone = this.gettOrderInfoData.Phone ? this.gettOrderInfoData.Phone.split('/')[0] : '';
        this.upData.ReceptPhone = this.gettOrderInfoData.seachPhone ? this.gettOrderInfoData.seachPhone : phone; //收货电话
        this.upData.ReceptAddress = this.gettOrderInfoData.GasAddress || this.gettOrderInfoData.IdentityAddress; //收货地址
        setTimeout(() => {
          this.setPayType();
        }, 500);
        // 切换用户 重新加载上一次的订单
        if (n.Id != o.Id) this.getRecordOrder();
      },
      deep: true,
    },
  },
  created() {
    this._GetDeliverConfig();
    let configData = JSON.parse(localStorage.getItem('softwareName'));
    if (configData) {
      this.softwareName = configData;
    }
    this.getPoint();
    this.ordType = this.$route.params.type;
    this.getSysConfig();
    
  },

  methods: {
    // 获取信息
    async _GetDeliverConfig() {
      let res = await getFeesConfigEditFloor();
      if (res?.Success) {
        this.FloorIsEnable = res.Data.IsEnable;
      }
    },
    // 判断用户默认支付方式
    setPayType() {
      if (this.$route.params.type != 'wxsl') {
        // 如果是优洁能用户 余额 > 月结 > 货到付款，  其他的按照原有逻辑上次下单的类型
        // location.hostname == 'callntyjn.bi-tac.cn'
        if (location.hostname == 'callntyjn.bi-tac.cn') {
          // 判断余额是否够支付
          if (this.gettOrderInfoData.Deposit >= this.upData.Money) {
            this.upData.OrderType = 6;
          } else if (this.gettOrderInfoData.IsMonthLimit && this.gettOrderInfoData.MonthLimit > this.upData.Money) {
            // 是月结用户则月结付款
            this.upData.OrderType = 3;
          } else {
            // 货到付款
            this.upData.OrderType = 1;
          }
        } else {
          // 如果是月结用户则默认月结付款 非微信受理
          if (this.gettOrderInfoData.IsMonthLimit) {
            this.upData.OrderType = 3;
          }
          let timeBoolen = this.dbTime(this.gettOrderInfoData.MonthLimitTime || '');
          if (this.gettOrderInfoData.IsMonthLimit && timeBoolen) {
            this.IsMonthLimit = true;
          }
        }
      }
    },
    dbTime(time) {
      // 获取本地的日期
      var currentDate = new Date();
      // 将日期转换为 yyyy-MM-dd 格式的字符串
      var formattedCurrentDate = currentDate.toISOString().split('T')[0];
      // 要比较的日期
      var otherDate = time; // 假设这是另一个日期
      // 比较日期大小
      if (formattedCurrentDate > otherDate) {
        return false;
      } else if (formattedCurrentDate < otherDate) {
        return true;
      } else {
        return true;
      }
    },
    closeFun() {
      this.$confirm('确定取消当前已填写的信息吗？')
        .then((_) => {
          this.showMortgage = false;
          this.getMortgageInfoFun({});
        })
        .catch((_) => {
          return;
        });
    },
    changePledge() {
      if (Object.keys(this.mortgageFrom).length > 0) {
        this.$confirm('当前有押瓶信息,取消将清空押瓶信息？')
          .then((_) => {
            this.upData.Money = Number(this.upData.Money || 0) - Number(this.upData.AmountReceivable || 0);
            this.getMortgageInfoFun({});
          })
          .catch((_) => {
            this.upData.CylinderPledge = true;
            return;
          });
      }
    },
    // 新增押品信息
    showPledgeBottle() {
      this.mortgageFrom.CustomerName = this.gettOrderInfoData.Name;
      this.mortgageFrom.CustomerPhone = this.gettOrderInfoData.seachPhone;
      this.upData.CustomerId = this.gettOrderInfoData.Id;
      this.showMortgage = true;
    },
    // 获取缓存的押瓶信息
    getMortgageInfoFun(params) {
      this.mortgageFrom = params;
      if (Object.keys(this.mortgageFrom).length > 0) {
        this.upData.AmountReceivable = JSON.parse(JSON.stringify(this.mortgageFrom)).AmountReceivable;
      } else {
        this.upData.AmountReceivable = 0;
      }
      this.upData.Money = this.priceMoney + Number(this.upData.AmountReceivable || 0) + Number(this.upData.FloorsMoney || 0);
      this.showMortgage = false;
    },
    // 获取配置判断身份证是否必输
    async getSysConfig() {
      let res = await getConfigAllList();
      let sysConfigFrom = JSON.parse(JSON.stringify(res.Data));
      if (res.Success) {
        sysConfigFrom.forEach((item) => {
          if (item.Key == 'PcLastDevlieryOperator') {
            this.PcLastDevlieryOperator = item.Value;
          }
          // 自提送气工是否必选
          if (item.Key == 'OrderDeliery') {
            this.gasWork = item.Value;
          }
          // 下单时间可修改
          if (item.Key == 'OrderTimeCanEdit') {
            this.orderTimeCanEdit = item.Value
            if(item.Value == '1' && this.ordType !== 'wxsl'){
              this.$set(this.upData, 'OrderTime', formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss'))
            }
          }
        });
      }
    },
    // 获取
    getMapInfo(params) {
      this.upData.OperatorId = params.id;
      this.upData.Operator = params.name;
      this.showMap = false;
    },
    // 获取地图点标记数据
    async showMapPosition() {
      if (!this.upData.ReceptAddress) {
        this.$message.error('请输入正确的收货地址');
        return;
      }
      if (!this.upData.CusStationId && !this.setStationId) {
        this.$message.error('请选择所属气站');
        return;
      }
      let params = {
        stationId: this.setStationId || this.upData.CusStationId,
        address: this.upData.ReceptAddress,
      };
      let res = await GetNearbyOperator(params);
      if (res.Success) {
        this.addressToPoint();
        this.mapPointData = res.Data;
      }
    },
    // 校验地址是否有效
    addressToPoint(city) {
      // 城市区域
      let geocoder = new AMap.Geocoder({
        city: city, //默认：“全国”
      });
      let that = this;
      geocoder.getLocation(this.upData.ReceptAddress, function(status, result) {
        if (status === 'complete' && result.geocodes.length) {
          let lnglat = result.geocodes[0].location;
          that.gasAddressPoint = [lnglat.lng, lnglat.lat];
          that.showMap = true;
        } else {
          that.$message.error('根据地址查询位置失败,请确认地址正确!');
        }
      });
    },
    childrenData(params) {
      this.upData = params;
      this.priceMoney = Number(this.upData?.Money || 0); //设置一个变量缓存商品金额
      this.upData.Money = this.priceMoney + Number(this.upData.FloorsMoney || 0) + Number(this.mortgageFrom.AmountReceivable || 0);
      // 非微信受理情况 南通优洁能 callntyjn.bi-tac.cn && location.hostname == 'callntyjn.bi-tac.cn'
      this.setPayType();
      // if (this.$route.params.type != 'wxsl' && location.hostname == 'callntyjn.bi-tac.cn') {
      //   // 判断余额是否够支付
      //   if (this.gettOrderInfoData.Deposit > this.upData.Money) {
      //     this.upData.OrderType = 6;
      //   } else if (this.gettOrderInfoData.IsMonthLimit && this.gettOrderInfoData.MonthLimit > this.upData.Money) {
      //     // 是月结用户则默认月结付款
      //     this.upData.OrderType = 3;
      //   } else {
      //     // 货到付款
      //     this.upData.OrderType = 1;
      //   }
      // }
    },
    setChangeTime(val) {
      this.setTimeValue = val;
      (this.upData.ReserveBeginTime = val ? val[0] : ''), (this.upData.ReserveEndTime = val ? val[1] : '');
    },
    async getPoint() {
      let res = await getGetPointsRedemption();
      if (res.Success) {
        this.pointMoney = res.Data;
      }
    },
    // 获取最近一次订单信息
    async getRecordOrder() {
      this.tableData = [];
      // wxsl :微信受理入口  web:web手动或来电下单
      let ordType = this.$route.params.type;
      let wxslOrdId,
        userId = '';
      if (ordType == 'wxsl') {
        wxslOrdId = this.$route.query.id;
      } else {
        userId = this.gettOrderInfoData.Id;
      }
      let ordParams = {
        OrderId: wxslOrdId,
        CusId: userId,
      };
      let res = await getLastOrder(ordParams);
      if (res.Success) {
        if (res.Data.LastOrder.length == 0) {
          // 没有历史订单，清除商品数据返回
          this.upData.Detail = [];
          return;
        }
        let resOneData = res.Data.LastOrder;
        let resDataInfo = JSON.parse(JSON.stringify(resOneData));
        this.upData.CouponMoney = res.Data.CouponMoney || 0;
        this.upData.Detail = resDataInfo;
        this.upData.PurchType = res.Data.PurchType;
        this.upData.PayMoney = res.Data?.PayMoney;
        this.upData.Money = res.Data?.Money;
        this.upData.PointMoney = res.Data?.PointMoney;
        this.upData.OrderType = res.Data.OrderType;
        this.upData.Remark = res.Data.Remark;
        this.setTimeValue = [res.Data.ReserveBeginTime || '', res.Data.ReserveEndTime || ''];
        this.upData.ReserveBeginTime = res.Data.ReserveBeginTime || '';
        this.upData.ReserveEndTime = res.Data.ReserveEndTime || '';
        // 如果是月结用户则默认月结付款
        if (this.gettOrderInfoData.IsMonthLimit && this.$route.params.type != 'wxsl') {
          this.upData.OrderType = 3;
        }
        if (this.PcLastDevlieryOperator == 1) {
          this.upData.OperatorId = res.Data.OperatorId;
          this.upData.Operator = res.Data.Operator;
        }
        if (this.upData.Detail === null) {
          this.upData.Detail = [];
        } else {
          let setMoney = 0;
          this.upData.Detail.forEach((item, index) => {
            setMoney += ordType == 'wxsl' ? item.Money : item.CurrentMoney;
            if (this.upData.PurchType == 0) {
              this.upData.Detail[index] = {
                Id: item.ProductId,
                ProductId: item.ProductId,
                Name: ordType == 'wxsl' ? item.ProductName : item.CurrentProductName, //产品名
                ProductName: ordType == 'wxsl' ? item.ProductName : item.CurrentProductName, //产品名
                ClassifyId: item.ClassifyId, // 商品分类Id
                ClassifyName: ordType == 'wxsl' ? item.ClassifyName : item.CurrentClassifyName, // 商品分类名称
                Price: ordType == 'wxsl' ? item.Price : item.CurrentPrice, // 单价
                Quantity: ordType == 'wxsl' ? item.Quantity || 1 : item.CurrentQuantity || 1, //数量默认1
                Money: ordType == 'wxsl' ? item.Quantity * item.Price : item.CurrentMoney, // 金额
              };
            } else {
              this.upData.Detail[index] = {
                Id: item.ProductId,
                ProductId: item.ProductId,
                Name: item.CurrentProductName, //产品名//产品名
                ProductName: item.CurrentProductName, //产品名
                ClassifyId: item.ClassifyId, // 商品分类Id
                ClassifyName: item.CurrentClassifyName, // 商品分类名称
                // Price: item.CurrentPrice, // 单价
                WeightPrice: item.CurrentWeightMoney, //// 重量单价
                CylinderCount: 1, //瓶数数量
                Quantity: 1, //重量 数量默认1
                Money: item.CurrentWeightMoney, // 金额
              };
            }
          });
          this.upData.Money = setMoney - (this.upData.CouponMoney || 0);
          this.priceMoney = Number(this.upData.Money || 0); //设置一个变量缓存商品金额
        }
        this.upData = JSON.parse(JSON.stringify(this.upData));
      }
    },
    // 配送工信息
    getRolePs(val) {
      this.upData.OperatorId = val.Id ? val.Id : null; //配送员Id
      this.upData.Operator = val.Name ? val.Name : null; //:配送员
    },
    getOrder: _.throttle(async function() {
      if (this.upData.OrderType == 5) {
        let params = {
          customerId: this.gettOrderInfoData.Id,
          money: Number(this.upData.Money),
        };
        if (this.ordType == 'wxsl') {
        } else {
          let pointMoney = await getCustomerPointByMoney(params);
          if (pointMoney.Success) {
            if (!pointMoney.Data) {
              this.$message.error('当前积分不足!');
              return;
            }
          } else {
            return;
          }
        }
      }
      if (Object.keys(this.gettOrderInfoData).length == 0 || !this.gettOrderInfoData.Id) {
        this.$message.error('请先保存用户信息!');
        return;
      }
      if (!this.gettOrderInfoData.StationId) {
        this.$message.error('请选择用户站点!');
        return;
      }
      let theValid;
      this.$refs['ordForm'].validate((valid) => {
        theValid = valid;
      });

      if (!theValid) return;
      if (this.upData.CylinderPledge && !this.mortgageFrom.Details) {
        this.$message.error('请填写押瓶信息');
        return;
      }
      if (Object.keys(this.mortgageFrom).length > 0) {
        this.upData.CylinderPledgeData = JSON.stringify(this.mortgageFrom);
      }
      this.btnLogin = true;
      this.upData.CusId = this.gettOrderInfoData.Id; //用户id
      this.upData.CusName = this.gettOrderInfoData.Name; //用户名称
      this.upData.CusNumber = this.gettOrderInfoData.Number; //用户证号
      this.upData.OrderPhone = this.gettOrderInfoData.Phone; //用户电话 ,下单电话
      this.upData.CusStationId = this.gettOrderInfoData.StationId; //气站id
      this.upData.CusStation = this.gettOrderInfoData.StationName; //气站名称
      this.upData.Money = Number(this.upData.Money);
      this.upData.OrderType = this.upData.OrderType ? this.upData.OrderType : 1; // 4 自提 1货到付款 5 积分
      // this.upData.OrderType =  1; // 4 自提 1货到付款
      this.upData.OperatorId = this.upData.OperatorId ? this.upData.OperatorId : null; //配送员Id
      this.jumpOrdOperatorId = this.upData.OperatorId;
      this.upData.Operator = this.upData.OperatorId ? this.upData.Operator : null; //:配送员

      //判断微信受理id
      let ordType = this.$route.params.type;

      if (ordType == 'wxsl') {
        let wxslId = this.$route.query.id;
        this.upData.Id = wxslId;
      }else{
        if(!this.upData.OrderTime){
          delete this.upData.OrderTime
        }
      }
      if (this.upData.Detail.length == 0) {
        this.$message({
          message: '请选择商品',
          type: 'warning',
        });
        this.btnLogin = false;
        return;
      }
      // this.$debounce(async () => {
      let res;
      // 带入新参数
      let callid = window.localStorage.getItem('callid');
      this.upData.callid = callid ? callid : '';
      // 下单加载
      const loading = this.$loading({
        lock: true,
        text: '正在下单中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      if (ordType == 'wxsl') {
        res = await postOrderAddWx(this.upData);
      } else {
        res = await postOrderAdd(this.upData);
      }
      if (res.Success) {
        window.localStorage.setItem('callid', '');
        this.mortgageFrom = {};
        this.$message({
          message: res.Msg || '成功',
          type: 'success',
        });
        this.btnLogin = false;
        window.localStorage.setItem('setMenusData', Math.random());
        // 本地缓存配置项数据
        let GETHTTPOBJ = window.localStorage.getItem('GETHTTPOBJ');
        if (GETHTTPOBJ && GETHTTPOBJ != 'undefined') {
          GETHTTPOBJ = JSON.parse(GETHTTPOBJ);
        }

        setTimeout(() => {
          // 当选择配送人员时，且配置项ordjumpPoint=true 下单之后跳转订单页面
          if (this.jumpOrdOperatorId && GETHTTPOBJ.ordJumpPrint) {
            // 如果是新订单设置打印时id
            if (GETHTTPOBJ.autoPrint && ordType != 'wxsl') {
              window.localStorage.setItem('wxslOrdId', res.Data.Id);
            }
            GETHTTPOBJ.MATHTYPE = Math.random();
            GETHTTPOBJ = JSON.stringify(GETHTTPOBJ);
            window.localStorage.setItem('GETHTTPOBJ', GETHTTPOBJ);
          }
          loading.close();
          window.close();
        }, 1000);
      } else {
        loading.close();
        // this.$message.error(res.Msg || '失败');
        return;
      }
      this.$store.dispatch('user/getOrderInfoData', {});
      // });
    }, 2000),
  },
};
</script>
<style lang="scss" scoped>
#customerSubscription {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.moiney-yp {
  width: 200px !important;
  margin-right: 10px;
  margin-left: 10px;
}
</style>
